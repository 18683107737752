.detailsLabel {
    font-weight: bold !important;
}

.closeIconStyle {
    display: "flex";
    justify-content: "end";
    margin-bottom: 1;
}
.mainContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.sidePanelContainer {
    min-width: 20%;
}

.dashboardContainer {
    display: flex;
    width: 100%;
    padding: 20px;
}

.cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    border-radius: 10px;
}

.cardTitle {
    font-size: 1rem !important; 
    font-weight: 700 !important;
    text-align: center;
    color:'red'
}

.cardSubtitle {
    font-size: 2rem !important;
    font-weight: 700 !important;
    text-align: center
}